
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import { Lato } from 'next/font/google';
import { CacheProvider } from '@emotion/react';
import { ApolloProvider } from '@apollo/client';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import theme from '../../theme';
import createEmotionCache from '../../createEmotionCache';
import { useApollo } from '../utilities/apolloClient';
const Toaster = dynamic(() => import('react-hot-toast').then((mod) => mod.Toaster), { ssr: false });
const cache = createEmotionCache();
const lato = Lato({
    weight: ['300', '400', '700'],
    subsets: ['latin'],
    preload: true
});
function App({ Component, emotionCache = cache, pageProps, apolloClient = useApollo(pageProps),
// menus,
 }) {
    const { menus } = pageProps;
    const getLayout = Component.getLayout || ((page) => page);
    const menuItems = menus?.headerMenuItems?.nodes ?? [];
    const footerMenuItems = menus?.footerMenuItems?.nodes ?? [];
    return (<>
            <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=AW-11267247038"/>
            <Script id="GA" strategy="afterInteractive" dangerouslySetInnerHTML={{
            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-11267247038');
                    `
        }}/>
            <ApolloProvider client={apolloClient}>
                <CacheProvider value={emotionCache}>
                    <CssVarsProvider theme={theme}>
                        <CssBaseline />
                        {getLayout(<Component {...pageProps}/>, {
            menuItems,
            className: lato.className,
            footerMenuItems
        })}
                    </CssVarsProvider>
                </CacheProvider>
            </ApolloProvider>
            <Toaster />
        </>);
}
const __Next_Translate__Page__190172ec9af__ = App;

    export default __appWithI18n(__Next_Translate__Page__190172ec9af__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  