import { extendTheme } from '@mui/joy/styles'

export default extendTheme({
    spacing: 4,
    fontFamily: {
        display: `Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
        body: `Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    },
    palette: {
        primary: {
            main: '#ffcc00',
        },
        secondary: {
            main: '#0d417d',
        },
        text: {
            main: '#0d417d',
        },
    },
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    solidBg: 'var(--joy-palette-primary-main)', // the initial background
                    solidColor: 'var(--joy-palette-text-main)', // the initial color
                    solidHoverBg: 'var(--joy-palette-primary-main)', // the :hover background
                    solidActiveBg: 'var(--joy-palette-primary-main)', // the :active background
                },
            },
        },
    },
    components: {
        JoyGrid: {
            styleOverrides: {
                root: {
                    paddingTop: '0rem',
                },
            },
        },
        JoyAspectRatio: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
                content: {
                    backgroundColor: 'transparent',
                },
            },
        },
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: '24px',
                    ...(ownerState.size === 'xs' && {
                        '--Icon-fontSize': '1rem',
                        '--Button-gap': '0.25rem',
                        minHeight: 'var(--Button-minHeight, 1.75rem)',
                        fontSize: theme.vars.fontSize.xs,
                        paddingBlock: '2px',
                        paddingInline: '0.5rem',
                    }),
                    ...(ownerState.size === 'xl' && {
                        '--Icon-fontSize': '2rem',
                        '--Button-gap': '1rem',
                        minHeight: 'var(--Button-minHeight, 4rem)',
                        fontSize: theme.vars.fontSize.xl,
                        paddingBlock: '0.5rem',
                        paddingInline: '2rem',
                    }),
                }),
            },
        },
    },
})
