module.exports = {
    locales: ['en', 'zh'],
    defaultLocale: 'en',
    pages: {
        '*': ['common', 'form'],
        '/blog': ['blog'],
        '/grants': ['grants'],
        '/business-financing': ['businessFinancing'],
    },
}
